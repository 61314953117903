import React from 'react';
import episodes from '../data/Episodes';

function KiwiHouse() {
  return (
    <div className="px-[5vw] mt-[1vh] space-y-8 mb-16">
      <div className="w-full">
        <div className="text-sm sm:text-md text-pretty font-montserrat font-light">
          Kiwi House is a radio show I hosted at{' '}
          <a
            className="uppercase underline hover:text-blue-700/80 hover:italic hover:font-normal cursor-pointer"
            href="https://beloved.wtf/kiwi-house "
          >
            beloved.wtf/kiwi-house
          </a>{' '}
          of all original songs, sketches, drones, and experiments to soundtrack your morning the
          third Saturday of every month. Recorded in my home, Kiwi House, named for my precious dogs
          Keela and Willow.
        </div>
      </div>
      <div className="w-full flex flex-col gap-6 sm:grid sm:grid-cols-2 sm:gap-12">
        {episodes.map((episode, index) => (
          <div key={index} className="flex flex-col font-montserrat font-light">
            <p className="text-xs whitespace-nowrap w-20">{episode.date}</p>
            <iframe title={episode.title} width="100%" height="125" src={episode.link}></iframe>
          </div>
        ))}
      </div>
    </div>
  );
}

export default KiwiHouse;
